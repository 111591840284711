import APIService from "./api.service";

export type RepairStagesType = {
    id?: number;
    title?: string;
};

class RepairStages extends APIService<RepairStagesType> {
    constructor() {
        super("/repair-stages");
    }
}

export default new RepairStages();
