import { z } from "zod";
import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";

export type Quarters = {
    q12020?: boolean;
    q22020?: boolean;
    q32020?: boolean;
    q42020?: boolean;
    q12021?: boolean;
    q22021?: boolean;
    q32021?: boolean;
    q42021?: boolean;
};

export type VmaFilters = {
    where?: {
        states?: string[] | null;
        effectIds?: number[] | null;
        industryGroupIds?: number[] | null;
        quarters?: Quarters | null;
    };
    limit?: number;
    offset?: number;
};

export const getVirtualMandateAssignments = async ({
    authToken,
    filters,
}: {
    authToken: string;
    filters?: VmaFilters;
}) => {
    const response = await makeRequest({
        method: "get",
        path: "/virtual-company-mandate-assignments",
        authToken,
        queryParams: { filter: JSON.stringify(filters) },
    });

    const vmas = parseSchema(
        z.array(
            z.object({
                id: z.string().uuid(),
                mandateId: z.number(),
                mandateEffectId: z.number(),
                industryGroupId: z.number().optional(),
            }),
        ),
        response.data.map((data: any) => ({ ...data })),
    );

    const countHeader = response.headers["x-count"];

    const count = countHeader ? Number.parseInt(countHeader, 10) : undefined;

    return { data: vmas, count };
};
