import { z } from "zod";

const entrySchema = z.object({
    id: z.string(),
    required: z.boolean().optional(),
    widthOf12Cols: z.number().optional(),
    widthOf300Cols: z.number().optional(),
    helpDocument: z.string().optional(),
    ruleGroups: z.array(z.any()).optional(),
    icon: z.string(),
    type: z.string(),
    value: z.string(),
    field: z.string(),
    description: z.string().optional(),
    selectBoxEntries: z.any(),
    password: z.boolean().optional(),
    fieldValue: z.any(), // fix typing
    ruleGroupsAreOr: z.boolean().optional(),
    defaultVal: z.string().optional(),
});

export type Entry = z.infer<typeof entrySchema>;

export const processflowSchema = z
    .object({
        id: z.number(),
        title: z.string(),
        causesRepContactOnCompletion: z.boolean(),
        checkboxEnabler: z.string(),
        checkboxEntries: z
            .array(
                z
                    .object({
                        field: z.string(),
                        value: z.string(),
                        description: z.string(),
                        selectBoxEntries: z.any(),
                    })
                    .strict(),
            )
            .nullable(),
        datePickerEntries: z.array(z.record(z.any())).nullable(),
        details: z.string(),
        entries: z.array(entrySchema.strict()).nullable(),
        fileUploadEntries: z.array(z.record(z.any())).nullable(),
        group: z.number(),
        helpDoc: z.number().nullable(), // Is this used?
        inputType: z.number(),
        internalComments: z.string(),
        internalOnly: z.number(),
        optional: z.number(),
        order: z.number().nullable(),
        reliesOnFalse: z.number(),
        reliesOnTrue: z.number(),
        selectBoxEntries: z.array(z.record(z.any())).nullable(),
        sendsEmailOnCompletion: z.number(),
        stage: z.number(),
        stateEntries: z.string().nullable(),
        textareaEntries: z
            .array(
                z
                    .object({
                        field: z.string(),
                        value: z.string(),
                        description: z.string(),
                    })
                    .strict(),
            )
            .nullable(),
        textfieldEntries: z.array(z.record(z.any())).nullable(),
        textfieldName: z.string(),
        yesNoEntries: z.string().nullable(),
        ruleGroupsAreOr: z.boolean().optional().default(false),
        businessRuleGroups: z.any(),
    })
    .strict();

export type ProcessFlow = z.infer<typeof processflowSchema>;
