import React from "react";
import KanbanBoard from "./components/KanbanBoard";

export default function Kanban() {
    return (
        <div className=" ">
            <KanbanBoard />
        </div>
    );
}
