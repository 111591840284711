import { useEffect, useMemo, useState } from "react";
import { User } from "../../jason-proof-of-concept/users/domain/user";
import RecordingButton from "../../layout/recording-button";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import { getProcessflowProgresses } from "../../jason-proof-of-concept/processflow-progresses/actions/get-processflow-progresses";
import Loader3 from "../../sections/utilities/Loader3";
import { useQuery } from "@tanstack/react-query";
import helpDocsService from "../../services/help-docs.service";
import CheckBoxNeoGenControlled from "../../layout/checkbox-controlled";
import { Select } from "../../layout/form/select-input";
import ButtonNeoGen from "../../layout/button-neogen";
import { CreatePackageTemplateModal } from "./modals/create-package-template-modal";
import { useHelpDocsTemplates } from "../../help-docs/hooks/use-help-docs-templates";
import { useHelpDocsTemplateItems } from "../../help-docs/hooks/use-help-docs-template-items";
import { EditPackageTemplateModal } from "./modals/edit-package-template-modal";
import { useDrop, useDrag } from "react-dnd";

const ITEM_TYPE = "HELP_DOC";

const DraggableCheckBox = ({
    id,
    title,
    isChecked,
    onToggle,
    onDrag,
}: {
    id: number;
    title: string;
    isChecked: boolean;
    onToggle: (id: number) => void;
    onDrag: (draggedId: number, targetId: number) => void;
}) => {
    // @ts-ignore
    const [{ isDragging }, drag] = useDrag({
        type: ITEM_TYPE,
        item: { id, title },
    });

    const [, drop] = useDrop({
        accept: ITEM_TYPE,
        hover: (draggedItem: { id: number }) => {
            if (draggedItem.id !== id) {
                onDrag(draggedItem.id, id);
            }
        },
    });

    return (
        <div ref={(node) => drag(drop(node))} style={{ opacity: isDragging ? 0.5 : 1 }}>
            <CheckBoxNeoGenControlled label={title} setValue={() => onToggle(id)} value={isChecked} name="" />
        </div>
    );
};

export default function Package({ companyId, user }: { companyId: number; user: User | undefined }) {
    const authToken = getAuthTokenNoThrow() || "";
    const [usersProgressId, setUsersProgressId] = useState(-1);
    const [selectedTemplate, setSelectedTemplate] = useState<null | number>(null);

    async function getProgress() {
        const [usersProgress] = await getProcessflowProgresses({
            authToken,
            filters: { userId: user?.id, group: 26 },
        });
        console.log(usersProgress);
        setUsersProgressId(usersProgress?.id || -1);
    }
    getProgress();

    const helpDocsQuery = useQuery(["HelpDocs"], async () => {
        const response = await helpDocsService.getAll();
        console.log(response);
        if (response) {
            return response.data;
        }
    });

    const helpDocsTemplatesQuery = useHelpDocsTemplates({
        authToken,
    });
    const helpDocsTemplates = useMemo(() => helpDocsTemplatesQuery.data || [], [helpDocsTemplatesQuery.data]);

    const helpDocsTemplateItemsQuery = useHelpDocsTemplateItems({
        authToken,
        filters: {
            where: { templateId: selectedTemplate },
        },
    });
    const helpDocsTemplateItems = useMemo(
        () => helpDocsTemplateItemsQuery.data || [],
        [helpDocsTemplateItemsQuery.data, selectedTemplate],
    );

    const [enabledDocs, setEnabledDocs] = useState<
        {
            id: number;
            isChecked: boolean;
        }[]
    >([]);

    const helpDocs = useMemo(() => helpDocsQuery.data || [], [helpDocsQuery.data]);

    useEffect(() => {
        if (helpDocs?.length > 0) {
            setEnabledDocs(helpDocs.map((doc) => ({ id: doc.id as number, isChecked: true })));
        }
    }, [helpDocs]);

    const [isCreateTemplateModalOpen, setIsCreateTemplateModalOpen] = useState(false);
    const [isEditTemplateModalOpen, setIsEditTemplateModalOpen] = useState(false);

    const onToggle = (id: number) => {
        const updatedEnabledDocs = [...enabledDocs];
        const docIndex = updatedEnabledDocs.findIndex((doc) => doc.id === id);
        updatedEnabledDocs[docIndex].isChecked = !updatedEnabledDocs[docIndex].isChecked;
        setEnabledDocs(updatedEnabledDocs);
    };

    const onDrag = (draggedId: number, targetId: number) => {
        const updatedEnabledDocs = [...enabledDocs];
        const draggedIndex = updatedEnabledDocs.findIndex((doc) => doc.id === draggedId);
        const targetIndex = updatedEnabledDocs.findIndex((doc) => doc.id === targetId);
        [updatedEnabledDocs[draggedIndex], updatedEnabledDocs[targetIndex]] = [
            updatedEnabledDocs[targetIndex],
            updatedEnabledDocs[draggedIndex],
        ];
        setEnabledDocs(updatedEnabledDocs);
    };

    return (
        <>
            {usersProgressId === -1 || helpDocsQuery.isLoading ? (
                <Loader3 />
            ) : (
                <>
                    <div className="m-5 grid grid-cols-3 gap-5">
                        <RecordingButton
                            title={"Generate Word Document"}
                            icon={"fas fa-file-word"}
                            download={
                                // "http://localhost:2000/word?processflowID=" +
                                // "https://generate-package.clearerc.com/word?processflowID=" +
                                (process.env.REACT_APP_GENERATE_PACKAGE_URL ??
                                    "https://generate-package.clearerc.com/word?processflowID=") +
                                usersProgressId +
                                "&helpDocs=" +
                                (selectedTemplate
                                    ? helpDocsTemplateItems.map((i) => i.helpDocId)
                                    : enabledDocs.filter((i) => i.isChecked).map((i) => i.id) || enabledDocs
                                ).join(",")
                            }
                        />
                        <RecordingButton title={"Generate PDF"} icon={"fas fa-file-pdf"} />
                        <RecordingButton title={"Generate Excel File"} icon={"fas fa-file-excel"} />
                    </div>
                    {isCreateTemplateModalOpen && (
                        <CreatePackageTemplateModal
                            onClose={() => {
                                setIsCreateTemplateModalOpen(false);
                                helpDocsTemplatesQuery.refetch();
                            }}
                        />
                    )}
                    {isEditTemplateModalOpen && selectedTemplate && (
                        <EditPackageTemplateModal
                            templateId={selectedTemplate}
                            name={helpDocsTemplates.find((template) => template.id === selectedTemplate)?.name || ""}
                            templateItems={helpDocsTemplateItems.filter((item) => item.templateId === selectedTemplate)}
                            onClose={() => {
                                setIsEditTemplateModalOpen(false);
                                helpDocsTemplatesQuery.refetch();
                                helpDocsTemplateItemsQuery.refetch();
                            }}
                        />
                    )}
                    <div className="m-5  gap-5 border p-5 rounded-xl dark:border-gray-600">
                        <div className="flex justify-between items-center mb-3">
                            <h1>Packages</h1>
                            <div className="flex items-end gap-2">
                                <ButtonNeoGen onClick={() => setIsCreateTemplateModalOpen(true)}>
                                    Create template
                                </ButtonNeoGen>
                                <ButtonNeoGen
                                    disabled={!selectedTemplate}
                                    onClick={() => setIsEditTemplateModalOpen(true)}
                                >
                                    Update template
                                </ButtonNeoGen>
                                <div>
                                    <Select
                                        value={selectedTemplate}
                                        options={helpDocsTemplates.map((item) => ({
                                            value: item.id?.toString() || "",
                                            label: item.name,
                                        }))}
                                        onChange={(e) => setSelectedTemplate(e as number)}
                                        label="Select template"
                                        placeholder="None selected"
                                    />
                                </div>
                            </div>
                        </div>
                        <p className="mb-3">Selected help docs:</p>

                        {selectedTemplate
                            ? helpDocsTemplateItems?.map((helpDoc) => {
                                  return (
                                      <div key={helpDoc.helpDocId}>
                                          <CheckBoxNeoGenControlled
                                              label={
                                                  helpDocsQuery.data?.find((item) => item.id === helpDoc.helpDocId)
                                                      ?.title
                                              }
                                              disabled={true}
                                              value={true}
                                              name={""}
                                          />
                                      </div>
                                  );
                              })
                            : enabledDocs?.map((helpDoc) => (
                                  <div key={helpDoc.id}>
                                      <DraggableCheckBox
                                          id={helpDoc.id}
                                          title={helpDocs.find((item) => item.id === helpDoc.id)?.title || ""}
                                          isChecked={helpDoc.isChecked}
                                          onToggle={onToggle}
                                          onDrag={onDrag}
                                      />
                                  </div>
                              ))}
                    </div>
                </>
            )}
        </>
    );
}
