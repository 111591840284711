import PlusIcon from "../icons/PlusIcon";
import { useEffect, useMemo, useState } from "react";
import { Column, Id, Task } from "../types";
import ColumnContainer from "./ColumnContainer";
import {
    DndContext,
    DragEndEvent,
    DragOverEvent,
    DragOverlay,
    DragStartEvent,
    PointerSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import { createPortal } from "react-dom";
import TaskCard from "./TaskCard";
import { de } from "date-fns/locale";
import PageDescription from "../page-description";
import { create, set } from "lodash";
import { useQuery } from "@tanstack/react-query";
import companyService from "../../services/company.service";
import companyRepairStagesService from "../../services/company-repair-stages.service";
import repairStagesService from "../../services/repair-stages.service";
import UserCompanyPicker from "../user-company-picker";
// const levels: string[] = [
//     "Pending",
//     "Ready for review",
//     "Active ",
//     "Sales appointment scheduled. ",
//     "Sales appointment needs to be rescheduled. ",
//     "Sales appointment completed Scheduled with substantiation team. ",
//     "Substantiation appointment needs to be rescheduled. ",
//     "Initial substantiation meeting complete Substation in progress. ",
//     "Substantiation complete ",
//     "Sent to Hedge Fund ",
// ];

const defaultTasks: Task[] = [
    {
        id: "1",
        columnId: "Pending",
        content: "List admin APIs for dashboard",
    },
    {
        id: "2",
        columnId: "Pending",
        content:
            "Develop user registration functionality with OTP delivered on SMS after email confirmation and phone number confirmation",
    },
    {
        id: "3",
        columnId: "Pending",
        content: "Conduct security testing",
    },
    {
        id: "4",
        columnId: "Pending",
        content: "Analyze competitors",
    },
    {
        id: "5",
        columnId: "Pending",
        content: "Create UI kit documentation",
    },
    {
        id: "6",
        columnId: "Pending",
        content: "Dev meeting",
    },
    {
        id: "7",
        columnId: "12",
        content: "Deliver dashboard prototype",
    },
    {
        id: "8",
        columnId: "12",
        content: "Optimize application performance",
    },
    {
        id: "9",
        columnId: "12",
        content: "Implement data validation",
    },
    {
        id: "10",
        columnId: "31",
        content: "Design database schema",
    },
    {
        id: "11",
        columnId: "13",
        content: "Integrate SSL web certificates into workflow",
    },
    {
        id: "12",
        columnId: "13",
        content: "Implement error logging and monitoring",
    },
    {
        id: "13",
        columnId: "13",
        content: "Design and implement responsive UI",
    },
];

function KanbanBoard() {
    const [level, setLevel] = useState(0);
    const [parent, setParent] = useState(0);
    const [columns, setColumns] = useState<string[]>([]);
    const [showCompanyPicker, setShowCompanyPicker] = useState(false);
    const [title, setTitle] = useState("Customer Onboarding");
    const [description, setDescription] = useState("Customer Onboarding");
    const [tasks, setTasks] = useState<Task[]>([]);
    const [isDropDisabled, setIsDropDisabled] = useState(false);
    const [activeColumn, setActiveColumn] = useState<Column | null>(null);
    const [movedFrom, setMovedFrom] = useState<Id | null>(null);

    const [activeTask, setActiveTask] = useState<Task | null>(null);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 1,
            },
        }),
    );

    const companiesQuery = useQuery(["companies"], async () => {
        const response = await companyService.getAll();
        if (response) {
            return response.data;
        }
    });
    const companyRepairStageQuery = useQuery(["companyRepairStages"], async () => {
        const response = await companyRepairStagesService.getAll();
        if (response) {
            return response.data;
        }
    });
    const repairStagesQuery = useQuery(["repairStages"], async () => {
        const response = await repairStagesService.getAll();
        if (response) {
            return response.data;
        }
    });

    useEffect(() => {
        if (companiesQuery.data && companyRepairStageQuery.data && repairStagesQuery.data) {
            setColumns(repairStagesQuery.data.map((repairStage) => repairStage.title ?? ""));
            setTasks(
                companyRepairStageQuery.data.map((companyRepairStage) => {
                    const repairStage = repairStagesQuery.data?.find(
                        (repairStage) => repairStage.id === companyRepairStage.stage,
                    );
                    const company = companiesQuery.data?.find((company) => company.id === companyRepairStage.id);
                    return {
                        id: companyRepairStage.id ?? -1,
                        columnId: repairStage?.title ?? "",
                        content: company?.name ?? "",
                    };
                }),
            );
        }
    }, [companiesQuery.data, companyRepairStageQuery.data, repairStagesQuery.data]);

    if (companiesQuery.isLoading || companyRepairStageQuery.isLoading || repairStagesQuery.isLoading) {
        return <div>Loading...</div>;
    }

    // useEffect(() => {
    //     setColumns(levels[parent][level]);
    //     // switch (level) {
    //     //     case 0:
    //     // setColumns(defaultCols);
    //     //         setTasks(defaultTasks);
    //     //         break;
    //     // }
    // }, [level, parent]);
    async function createCompanyRepairStage(newEntry: { id: number; stage: number }) {
        await companyRepairStagesService.createWithId(newEntry);
        await companyRepairStageQuery.refetch();
        setShowCompanyPicker(false);
    }

    function onDragStart(event: DragStartEvent) {
        // if (event.active.data.current?.type === "Column") {
        //     setActiveColumn(event.active.data.current.column);
        //     return;
        // }
        // setIsDropDisabled(event === "xyz");
        // console.log("From " + tasks[activeIndex].columnId + " to " + overId);
        // setMovedFrom(tasks[activeIndex].columnId);
        if (event.active.data.current?.type === "Task") {
            setActiveTask(event.active.data.current.task);
            setMovedFrom(event.active.data.current.task.columnId);
            return;
        }
    }

    function onDragEnd(event: DragEndEvent) {
        // console.error(event);

        setActiveColumn(null);
        setActiveTask(null);

        const { active, over } = event;
        if (!over) return;

        const activeId = active.id;
        const overColumn = over.data.current?.task?.columnId;
        const overColumnId = repairStagesQuery.data?.find((repairStage) => repairStage.title === overColumn)?.id;
        const overId = over.id;
        console.log({ movedFrom, overColumn });
        if (!overColumnId) return;
        companyRepairStagesService.update(activeId, { stage: overColumnId });
        companyRepairStageQuery.refetch();
        // console.error({ Company: activeId, overColumnId });

        if (activeId === overId) return;

        // const isActiveAColumn = active.data.current?.type === "Column";
        // if (!isActiveAColumn) return;

        // console.log("DRAG END");

        // setColumns((columns) => {
        //     const activeColumnIndex = columns.findIndex((col) => col.id === activeId);

        //     const overColumnIndex = columns.findIndex((col) => col.id === overId);

        //     return arrayMove(columns, activeColumnIndex, overColumnIndex);
        // });
    }

    function onDragOver(event: DragOverEvent) {
        const { active, over } = event;
        if (!over) return;

        const activeId = active.id;
        const overId = over.id;

        if (activeId === overId) return;

        const isActiveATask = active.data.current?.type === "Task";
        const isOverATask = over.data.current?.type === "Task";

        if (!isActiveATask) return;

        // Im dropping a Task over another Task
        if (isActiveATask && isOverATask) {
            setTasks((tasks) => {
                const activeIndex = tasks.findIndex((t) => t.id === activeId);
                const overIndex = tasks.findIndex((t) => t.id === overId);

                if (tasks[activeIndex].columnId != tasks[overIndex].columnId) {
                    // if (checkCanMove)
                    tasks[activeIndex].columnId = tasks[overIndex].columnId;
                    return arrayMove(tasks, activeIndex, overIndex - 1);
                }

                return arrayMove(tasks, activeIndex, overIndex);
            });
        }

        const isOverAColumn = over.data.current?.type === "Column";

        // Im dropping a Task over a column
        if (isActiveATask && isOverAColumn) {
            setTasks((tasks) => {
                const activeIndex = tasks.findIndex((t) => t.id === activeId);

                tasks[activeIndex].columnId = overId;
                // console.log("DROPPING TASK OVER COLUMN", { activeIndex, overId, id: tasks[activeIndex] });
                // console.error(event);
                // const overColumn = over.data.current?.task?.columnId;
                const overColumnId = repairStagesQuery.data?.find((repairStage) => repairStage.title === overId)?.id;
                companyRepairStagesService.update(event.active.id, { stage: overColumnId });
                // companyRepairStageQuery.refetch();

                return arrayMove(tasks, activeIndex, activeIndex);
            });
        }
    }
    return (
        <>
            <PageDescription
                title={"ERC Repair Stage"}
                description="ERC Repair Stage"
                buttons={[
                    {
                        label: "Add Existing Company",
                        onClick: () => {
                            setShowCompanyPicker(true);
                        },
                    },
                    // {
                    //     label: "Add New Company",
                    //     onClick: () => {
                    //         console.log("Add");
                    //     },
                    // },
                ]}
            />
            <div
                className="
        mb-auto
        flex
        pt-0
        w-full
        items-center
        overflow-x-auto
        overflow-y-hidden
        
    "
            >
                <DndContext
                    autoScroll={{
                        acceleration: 1,
                        threshold: {
                            x: 0.01,
                            y: 0.02,
                        },
                        interval: 100,
                    }}
                    sensors={sensors}
                    onDragStart={onDragStart}
                    onDragEnd={onDragEnd}
                    onDragOver={onDragOver}
                >
                    <div className="mx-auto flex gap-4">
                        <div className="flex gap-4">
                            {/* <SortableContext items={columnsId}> */}
                            {columns.map((col) => (
                                <ColumnContainer
                                    key={col}
                                    column={col}
                                    tasks={tasks.filter((task) => task.columnId === col)}
                                />
                            ))}
                            {/* </SortableContext> */}
                        </div>
                    </div>
                </DndContext>
            </div>
            <UserCompanyPicker
                showUsers={false}
                companyList={companiesQuery.data ?? []}
                open={showCompanyPicker}
                setOpen={setShowCompanyPicker}
                setCompany={(company) => {
                    console.log(company);
                    const newEntry = {
                        id: company.id,
                        stage: 1,
                    };
                    console.log(newEntry);
                    createCompanyRepairStage(newEntry);
                }}
            />
        </>
    );
}

export default KanbanBoard;
