import { useState, useEffect } from "react";
import useDebounce from "../hooks/useDebounce";
import { Label } from "./label";
import PopoverComponent from "./popover-component";

export default function TextAreaNeoGenControlled({
    value,
    setValue,
    name,
    label,
    rows,
    errors,
    description,
    required,
    helpDocument,
    ...rest
}: TextAreaNeoGenProps) {
    const [text, setText] = useState(value);
    const debounced = useDebounce(text, 500);

    useEffect(() => {
        setValue(debounced);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debounced]);

    useEffect(() => {
        setText(value);
    }, [value]);

    return (
        <div className="mb-5">
            <Label htmlFor={name} text={label} isRequired={!!required} />
            {helpDocument && (
                <>
                    <PopoverComponent document={helpDocument} />
                </>
            )}
            <div className="mt-1">
                <textarea
                    rows={rows ?? 5}
                    name={name}
                    id={name}
                    // {...props.register(props.name, {required: props.rules?.required??false})}
                    value={text}
                    onChange={(e) => setText(e.currentTarget.value)}
                    {...rest}
                    className={
                        "dark:bg-gray-800 dark:text-gray-400 rounded-md" +
                        " " +
                        ((errors ?? []).length > 0
                            ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                            : "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md dark:bg-gray-900  dark:border-gray-900 dark:text-gray-300")
                    }
                />
                <p id="comments-description" className="text-gray-500 text-xs tracking-wider leading-4">
                    {description}
                </p>
            </div>
        </div>
    );
}
type TextAreaNeoGenProps = {
    rows?: number;
    name?: string;
    label: string;
    description?: string;
    onEnter?: () => void;
    // contents: string,
    // defaultValue: string,
    // register: any,
    errors?: string[];
    required?: boolean;
    value: string;
    setValue: (e: string) => void;
    rules?: any;
    rest?: any;
    // setContents: (e:string) => void,
    notFullWidth?: boolean;
    helpDocument?: string;
};
